
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93ILszah2VNpMeta } from "/app/pages/[...slug].vue?macro=true";
import { default as _91slug_9350gihDaK4XMeta } from "/app/pages/blog/articles/[slug].vue?macro=true";
import { default as index1dERBYSZ88Meta } from "/app/pages/blog/articles/index.vue?macro=true";
import { default as _91slug_93GJQfGcajTDMeta } from "/app/pages/blog/authors/[slug].vue?macro=true";
import { default as indexkbmjBHa1niMeta } from "/app/pages/blog/authors/index.vue?macro=true";
import { default as _91slug_93OqczCn2NfnMeta } from "/app/pages/blog/categories/[slug].vue?macro=true";
import { default as indexzPYvaTRYjYMeta } from "/app/pages/blog/categories/index.vue?macro=true";
import { default as indexgWaMlevJ7RMeta } from "/app/pages/blog/index.vue?macro=true";
import { default as _91id_933vpBTPv24UMeta } from "/app/pages/careers/[id].vue?macro=true";
import { default as indexpW1WXp0mgAMeta } from "/app/pages/careers/index.vue?macro=true";
import { default as _91slug_935xGnk5JrpsMeta } from "/app/pages/events/[slug].vue?macro=true";
import { default as indexWgIHVzns1AMeta } from "/app/pages/events/index.vue?macro=true";
import { default as indexOHAOB0Xe8QMeta } from "/app/pages/features/index.vue?macro=true";
import { default as hubspotJWlGAbVZpNMeta } from "/app/pages/hubspot.vue?macro=true";
import { default as implementation_45satisfaction_45surveyjqjCUtODkdMeta } from "/app/pages/implementation-satisfaction-survey.vue?macro=true";
import { default as _91_46_46_46slug_93HPdOXV7atCMeta } from "/app/pages/lp/[...slug].vue?macro=true";
import { default as newsletterVEF8u8KZkVMeta } from "/app/pages/newsletter.vue?macro=true";
import { default as in_45the_45newsqvOh9nLTjOMeta } from "/app/pages/newsroom/in-the-news.vue?macro=true";
import { default as indexgyJRKObFgMMeta } from "/app/pages/newsroom/index.vue?macro=true";
import { default as our_45awardsnWR18hhzCuMeta } from "/app/pages/newsroom/our-awards.vue?macro=true";
import { default as press_45releasesMt2bLjSSqPMeta } from "/app/pages/newsroom/press-releases.vue?macro=true";
import { default as _91slug_93v0788kKEGGMeta } from "/app/pages/partners/[slug].vue?macro=true";
import { default as indexLG3Wtz8U6fMeta } from "/app/pages/partners/index.vue?macro=true";
import { default as _91slug_93qXxHi4VqnPMeta } from "/app/pages/reports/[slug].vue?macro=true";
import { default as indexcYLHwCDNydMeta } from "/app/pages/reports/index.vue?macro=true";
import { default as trusted_45aiFqVG0iw9HXMeta } from "/app/pages/trusted-ai.vue?macro=true";
import { default as indexxU0kH3qBKdMeta } from "/app/pages/vlogs/index.vue?macro=true";
import { default as indexIbGDnNNySuMeta } from "/app/pages/webinars/index.vue?macro=true";
import { default as indexLXREpitJ99Meta } from "../pages/blog/articles/index.vue?macro=true";
import { default as component_45stubWiEcus9N6cMeta } from "/app/node_modules/.pnpm/nuxt@3.15.2_eslint@9.18.0_rollup@4.30.1_sass@1.83.4_typescript@5.7.3_vite@6.0.7/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubWiEcus9N6c } from "/app/node_modules/.pnpm/nuxt@3.15.2_eslint@9.18.0_rollup@4.30.1_sass@1.83.4_typescript@5.7.3_vite@6.0.7/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___en",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93ILszah2VNpMeta || {},
    component: () => import("/app/pages/[...slug].vue")
  },
  {
    name: "slug___de",
    path: "/de/:slug(.*)*",
    meta: _91_46_46_46slug_93ILszah2VNpMeta || {},
    component: () => import("/app/pages/[...slug].vue")
  },
  {
    name: "blog-articles-slug___en",
    path: "/blog/articles/:slug()",
    component: () => import("/app/pages/blog/articles/[slug].vue")
  },
  {
    name: "blog-articles___en",
    path: "/blog/articles",
    component: () => import("/app/pages/blog/articles/index.vue")
  },
  {
    name: "blog-authors-slug___en",
    path: "/blog/authors/:slug()",
    component: () => import("/app/pages/blog/authors/[slug].vue")
  },
  {
    name: "blog-authors___en",
    path: "/blog/authors",
    component: () => import("/app/pages/blog/authors/index.vue")
  },
  {
    name: "blog-categories-slug___en",
    path: "/blog/categories/:slug()",
    component: () => import("/app/pages/blog/categories/[slug].vue")
  },
  {
    name: "blog-categories___en",
    path: "/blog/categories",
    component: () => import("/app/pages/blog/categories/index.vue")
  },
  {
    name: "blog___en",
    path: "/blog",
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "careers-id___en",
    path: "/careers/:id()",
    component: () => import("/app/pages/careers/[id].vue")
  },
  {
    name: "careers___en",
    path: "/careers",
    component: () => import("/app/pages/careers/index.vue")
  },
  {
    name: "events-slug___en",
    path: "/events/:slug()",
    component: () => import("/app/pages/events/[slug].vue")
  },
  {
    name: "events-slug___de",
    path: "/de/events/:slug()",
    component: () => import("/app/pages/events/[slug].vue")
  },
  {
    name: "events___en",
    path: "/events",
    component: () => import("/app/pages/events/index.vue")
  },
  {
    name: "events___de",
    path: "/de/events",
    component: () => import("/app/pages/events/index.vue")
  },
  {
    name: "features___en",
    path: "/features",
    component: () => import("/app/pages/features/index.vue")
  },
  {
    name: "hubspot___en",
    path: "/hubspot",
    component: () => import("/app/pages/hubspot.vue")
  },
  {
    name: "implementation-satisfaction-survey___en",
    path: "/implementation-satisfaction-survey",
    meta: implementation_45satisfaction_45surveyjqjCUtODkdMeta || {},
    component: () => import("/app/pages/implementation-satisfaction-survey.vue")
  },
  {
    name: "lp-slug___en",
    path: "/lp/:slug(.*)*",
    meta: _91_46_46_46slug_93HPdOXV7atCMeta || {},
    component: () => import("/app/pages/lp/[...slug].vue")
  },
  {
    name: "lp-slug___de",
    path: "/de/lp/:slug(.*)*",
    meta: _91_46_46_46slug_93HPdOXV7atCMeta || {},
    component: () => import("/app/pages/lp/[...slug].vue")
  },
  {
    name: "newsletter___en",
    path: "/newsletter",
    component: () => import("/app/pages/newsletter.vue")
  },
  {
    name: "newsletter___de",
    path: "/de/newsletter",
    component: () => import("/app/pages/newsletter.vue")
  },
  {
    name: "newsroom-in-the-news___en",
    path: "/newsroom/in-the-news",
    component: () => import("/app/pages/newsroom/in-the-news.vue")
  },
  {
    name: "newsroom___en",
    path: "/newsroom",
    component: () => import("/app/pages/newsroom/index.vue")
  },
  {
    name: "newsroom-our-awards___en",
    path: "/newsroom/our-awards",
    component: () => import("/app/pages/newsroom/our-awards.vue")
  },
  {
    name: "newsroom-press-releases___en",
    path: "/newsroom/press-releases",
    component: () => import("/app/pages/newsroom/press-releases.vue")
  },
  {
    name: "partners-slug___en",
    path: "/partners/:slug()",
    meta: _91slug_93v0788kKEGGMeta || {},
    component: () => import("/app/pages/partners/[slug].vue")
  },
  {
    name: "partners___en",
    path: "/partners",
    component: () => import("/app/pages/partners/index.vue")
  },
  {
    name: "reports-slug___en",
    path: "/reports/:slug()",
    meta: _91slug_93qXxHi4VqnPMeta || {},
    component: () => import("/app/pages/reports/[slug].vue")
  },
  {
    name: "reports-slug___de",
    path: "/de/reports/:slug()",
    meta: _91slug_93qXxHi4VqnPMeta || {},
    component: () => import("/app/pages/reports/[slug].vue")
  },
  {
    name: "reports___en",
    path: "/reports",
    component: () => import("/app/pages/reports/index.vue")
  },
  {
    name: "reports___de",
    path: "/de/reports",
    component: () => import("/app/pages/reports/index.vue")
  },
  {
    name: "trusted-ai___en",
    path: "/trusted-ai",
    meta: trusted_45aiFqVG0iw9HXMeta || {},
    component: () => import("/app/pages/trusted-ai.vue")
  },
  {
    name: "trusted-ai___de",
    path: "/de/trusted-ai",
    meta: trusted_45aiFqVG0iw9HXMeta || {},
    component: () => import("/app/pages/trusted-ai.vue")
  },
  {
    name: "vlogs___en",
    path: "/vlogs",
    component: () => import("/app/pages/vlogs/index.vue")
  },
  {
    name: "vlogs___de",
    path: "/de/vlogs",
    component: () => import("/app/pages/vlogs/index.vue")
  },
  {
    name: "webinars___en",
    path: "/webinars",
    component: () => import("/app/pages/webinars/index.vue")
  },
  {
    name: "webinars___de",
    path: "/de/webinars",
    component: () => import("/app/pages/webinars/index.vue")
  },
  {
    path: "/blog/articles/page-0",
    name: "articles zero page",
    redirect: "/blog/articles"
  },
  {
    path: "/blog/articles/page-1",
    name: "articles first page",
    redirect: "/blog/articles"
  },
  {
    name: "articles pagination___en",
    path: "/blog/articles/page-:pageNumber(\\d+)",
    component: () => import("../pages/blog/articles/index.vue")
  },
  {
    name: "articles pagination___de",
    path: "/de/blog/articles/page-:pageNumber(\\d+)",
    component: () => import("../pages/blog/articles/index.vue")
  },
  {
    name: component_45stubWiEcus9N6cMeta?.name,
    path: "/diagnostic-engine",
    component: component_45stubWiEcus9N6c
  },
  {
    name: component_45stubWiEcus9N6cMeta?.name,
    path: "/what-can-we-learn-about-people-using-symptom-checkers",
    component: component_45stubWiEcus9N6c
  },
  {
    name: component_45stubWiEcus9N6cMeta?.name,
    path: "/de/what-can-we-learn-about-people-using-symptom-checkers",
    component: component_45stubWiEcus9N6c
  },
  {
    name: component_45stubWiEcus9N6cMeta?.name,
    path: "/de/diagnostic-engine",
    component: component_45stubWiEcus9N6c
  },
  {
    name: component_45stubWiEcus9N6cMeta?.name,
    path: "/product/infermedica-api",
    component: component_45stubWiEcus9N6c
  },
  {
    name: component_45stubWiEcus9N6cMeta?.name,
    path: "/de/product/infermedica-api",
    component: component_45stubWiEcus9N6c
  },
  {
    name: component_45stubWiEcus9N6cMeta?.name,
    path: "/product/intake-form",
    component: component_45stubWiEcus9N6c
  },
  {
    name: component_45stubWiEcus9N6cMeta?.name,
    path: "/de/product/intake-form",
    component: component_45stubWiEcus9N6c
  },
  {
    name: component_45stubWiEcus9N6cMeta?.name,
    path: "/pl/product/intake-form",
    component: component_45stubWiEcus9N6c
  },
  {
    name: component_45stubWiEcus9N6cMeta?.name,
    path: "/events/hlth-2022",
    component: component_45stubWiEcus9N6c
  },
  {
    name: component_45stubWiEcus9N6cMeta?.name,
    path: "/external-validation",
    component: component_45stubWiEcus9N6c
  },
  {
    name: component_45stubWiEcus9N6cMeta?.name,
    path: "/de/external-validation",
    component: component_45stubWiEcus9N6c
  },
  {
    name: component_45stubWiEcus9N6cMeta?.name,
    path: "/de/industries/health-plans",
    component: component_45stubWiEcus9N6c
  },
  {
    name: component_45stubWiEcus9N6cMeta?.name,
    path: "/case-studies/solv-full-read",
    component: component_45stubWiEcus9N6c
  },
  {
    name: component_45stubWiEcus9N6cMeta?.name,
    path: "/de/case-studies/solv-full-read",
    component: component_45stubWiEcus9N6c
  },
  {
    name: component_45stubWiEcus9N6cMeta?.name,
    path: "/press",
    component: component_45stubWiEcus9N6c
  },
  {
    name: component_45stubWiEcus9N6cMeta?.name,
    path: "/de/press",
    component: component_45stubWiEcus9N6c
  }
]