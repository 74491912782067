<template>
  <section class="form-with-calendar" v-editable="blok">
    <div class="form-with-calendar__wrapper">
      <div
        class="form-with-calendar__controls"
        aria-label="Form tab controls"
        :class="{
          'form-with-calendar__controls--calendar': tabPosition === 1,
        }"
      >
        <button
          @click="setActiveTab('form')"
          class="form-with-calendar__tab-selector"
          :class="{
            'form-with-calendar__tab-selector--active': tabPosition === 0,
          }"
        >
          {{ t('common.form_calendar_section.get_in_touch') }}
        </button>
        <button
          @click="setActiveTab('calendar')"
          class="form-with-calendar__tab-selector"
          :class="{
            'form-with-calendar__tab-selector--active': tabPosition === 1,
          }"
        >
          {{ t('common.form_calendar_section.schedule_intro') }}
        </button>
      </div>
      <div
        class="form-with-calendar__tab-content"
        id="tab-form"
        v-show="currentTab === 'form'"
      >
        <StoryblokComponent
          v-for="childBlok in blok.form"
          :key="childBlok._uid"
          :blok="childBlok"
        />
      </div>
      <div
        class="form-with-calendar__tab-content"
        v-show="currentTab === 'calendar'"
        id="tab-calendar"
      >
        <FormHsCalendarsSection @go-to-form="setActiveTab('form')" />
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import type { SbFormWithCalendarTab } from '@/types'
import gsap from 'gsap'

const currentTab = ref<SbFormWithCalendarTab['default_tab']>('form')
const tabPosition = ref(0)

const setActiveTab = (tab: SbFormWithCalendarTab['default_tab']) => {
  if (tab === currentTab.value) return

  tabPosition.value = tab === 'form' ? 0 : 1

  const tl = gsap.timeline()

  if (tab === 'form') {
    tl.to('#tab-calendar', { opacity: 0, y: 20, duration: 0.3 }).then(() => {
      currentTab.value = tab
      tl.fromTo(
        '#tab-form',
        { opacity: 0, y: 20 },
        { opacity: 1, y: 0, duration: 0.3 },
      )
    })
  } else {
    tl.to('#tab-form', { opacity: 0, y: 20, duration: 0.3 }).then(() => {
      currentTab.value = tab
      tl.fromTo(
        '#tab-calendar',
        { opacity: 0, y: 20 },
        { opacity: 1, y: 0, duration: 0.3 },
      )
    })
  }
}

interface Props {
  blok: SbFormWithCalendarTab
}

const props = defineProps<Props>()

const { t } = useI18n()

onMounted(() => {
  currentTab.value = props.blok.default_tab
  tabPosition.value = props.blok.default_tab === 'form' ? 0 : 1
})
</script>

<style lang="scss" scoped>
.form-with-calendar {
  &__controls {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border-bottom: 2px solid var(--gray-400);
    margin-bottom: 40px;

    &::after {
      // active indicator with subtle animation
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 50%;
      height: 2px;
      background-color: var(--cobalt-700);
      z-index: 1;
      transition: transform 0.45s;
    }

    &--calendar {
      &::after {
        transform: translateX(100%);
      }
    }
  }

  &__tab-selector {
    padding-bottom: 24px;
    font-size: 20px;
    font-weight: 700;
    transition: color 0.3s;

    &--active {
      color: var(--cobalt-600);
    }
  }
}
</style>
