<template>
  <section class="calendars">
    <div class="calendars__section">
      <p class="calendars__section-title">
        {{ t('common.form_calendar_section.select_region') }}
      </p>
      <div class="calendars__controls">
        <FormBaseRadioButton
          v-model="region"
          radio-group-id="calendars-region"
          :label="t('common.form_calendar_section.region_us')"
          value="us"
          size="s"
        />
        <FormBaseRadioButton
          v-model="region"
          radio-group-id="calendars-region"
          :label="t('common.form_calendar_section.region_all')"
          value="all"
          size="s"
        />
      </div>
    </div>
    <div class="calendars__section" id="form-calendar-section">
      <p class="calendars__section-title">
        {{ t('common.form_calendar_section.choose_date') }}
      </p>
      <p class="paragraph--xs calendars__section-note">
        {{ t('common.form_calendar_section.note') }}
      </p>
      <HsIframeForm
        v-for="reg in Object.keys(hsCalendarLinks)"
        :key="reg"
        :src="hsCalendarLinks[reg as regions]"
        v-show="reg === region"
      />
      <Translation
        tag="p"
        class="calendars__link-to-form paragraph--s"
        keypath="common.form_calendar_section.if_no_time"
      >
        <template #if_no_time_link>
          <NuxtLink @click="emit('goToForm')">
            {{ $t('common.form_calendar_section.if_no_time_link') }}
          </NuxtLink>
        </template>
      </Translation>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { Translation } from 'vue-i18n'
type regions = 'us' | 'all'

const region = ref<regions>('us')

const hsCalendarLinks = {
  all: 'https://meetings.hubspot.com/julia-zebrowska/sdr-calendar-julia-?embed=true',
  us: 'https://meetings.hubspot.com/ronald-owen?embed=true',
}

const { t } = useI18n()
const { scrollToElement } = useScrollToElement()
const emit = defineEmits(['goToForm'])

watch(region, () => {
  scrollToElement('form-calendar-section')
})
</script>

<style lang="scss" scoped>
.calendars {
  position: relative;

  &__controls {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-width: 400px;
  }

  &__section-title {
    text-align: left;
    font-weight: 600;
    display: block;
    font-size: var(--font-size-paragraph-s);
  }

  &__section-note {
    text-align: left;
  }

  &__section {
    margin-bottom: 56px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  &__link-to-form {
    max-width: 400px;
    margin: 24px auto 0;
    text-align: left;
  }
}
</style>
