import routerOptions0 from "/app/node_modules/.pnpm/nuxt@3.15.2_eslint@9.18.0_rollup@4.30.1_sass@1.83.4_typescript@5.7.3_vite@6.0.7/node_modules/nuxt/dist/pages/runtime/router.options.js";
const configRouterOptions = {
  strict: true,
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = false
export default {
...configRouterOptions,
...routerOptions0,
}