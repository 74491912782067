import type { ISbStoryData } from '@storyblok/js'


import type {
  SbFooterStoryblok,
  SbPromoStoryblok,
  SbPageStoryblok,
  StoryblokData,
} from '@/types'

export const useMainStore = defineStore('main', () => {
  const storyData = ref<ISbStoryData<any>>({} as ISbStoryData<SbPageStoryblok>)
  const footer = ref<StoryblokData<SbFooterStoryblok> | null>(null)
  const promo = ref<StoryblokData<SbPromoStoryblok> | null>(null)
  const isModalVisible = ref(false)
  const availableLanguages = ref<string[] | null>(null)

  const promobarColor = computed(() => {
    return storyData.value.content?.promobar_color || 'cobalt'
  })

  return {
    storyData,
    footer,
    promo,
    isModalVisible,
    availableLanguages,
    promobarColor,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMainStore, import.meta.hot))
}
