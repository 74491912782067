import payload_plugin_msm90eZbbJ from "/app/node_modules/.pnpm/@pinia+nuxt@0.9.0_pinia@2.3.0_rollup@4.30.1/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_rwq6Pu0rN9 from "/app/node_modules/.pnpm/nuxt@3.15.2_eslint@9.18.0_rollup@4.30.1_sass@1.83.4_typescript@5.7.3_vite@6.0.7/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_GdPLLd1Gxq from "/app/node_modules/.pnpm/nuxt@3.15.2_eslint@9.18.0_rollup@4.30.1_sass@1.83.4_typescript@5.7.3_vite@6.0.7/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_5NZl3mtcvD from "/app/node_modules/.pnpm/nuxt@3.15.2_eslint@9.18.0_rollup@4.30.1_sass@1.83.4_typescript@5.7.3_vite@6.0.7/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_T0llBdL9QK from "/app/node_modules/.pnpm/nuxt-site-config@2.2.21_rollup@4.30.1_vite@6.0.7_vue@3.5.13/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import navigation_repaint_client_L4RUCNReIt from "/app/node_modules/.pnpm/nuxt@3.15.2_eslint@9.18.0_rollup@4.30.1_sass@1.83.4_typescript@5.7.3_vite@6.0.7/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Xass8bpYhi from "/app/node_modules/.pnpm/nuxt@3.15.2_eslint@9.18.0_rollup@4.30.1_sass@1.83.4_typescript@5.7.3_vite@6.0.7/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_lRoLo0ZUQC from "/app/node_modules/.pnpm/nuxt@3.15.2_eslint@9.18.0_rollup@4.30.1_sass@1.83.4_typescript@5.7.3_vite@6.0.7/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_QPx4pk0FOl from "/app/node_modules/.pnpm/@pinia+nuxt@0.9.0_pinia@2.3.0_rollup@4.30.1/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_E2HMxhKusz from "/app/node_modules/.pnpm/nuxt@3.15.2_eslint@9.18.0_rollup@4.30.1_sass@1.83.4_typescript@5.7.3_vite@6.0.7/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt_plugin_jF368xjFow from "/app/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_rollup@4.30.1/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import switch_locale_path_ssr_JfaaupOAPM from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_rollup@4.30.1_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_1BD1DB1WGj from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_rollup@4.30.1_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_GLUs1mtuUK from "/app/node_modules/.pnpm/@storyblok+nuxt@6.2.2_vue@3.5.13/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
import motion_r3MovnDDtb from "/app/node_modules/.pnpm/@vueuse+motion@2.2.6_rollup@4.30.1_vue@3.5.13/node_modules/@vueuse/motion/dist/nuxt/runtime/templates/motion.js";
import plugin_wy0B721ODc from "/app/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import clickOutsideDirective_JuSoe6vGeC from "/app/plugins/clickOutsideDirective.ts";
import fetchGlobalData_EOfhKKMol6 from "/app/plugins/fetchGlobalData.ts";
import onLanguageSwitch_Zq3lupusSn from "/app/plugins/onLanguageSwitch.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
export default [
  payload_plugin_msm90eZbbJ,
  revive_payload_client_rwq6Pu0rN9,
  unhead_GdPLLd1Gxq,
  router_5NZl3mtcvD,
  _0_siteConfig_T0llBdL9QK,
  navigation_repaint_client_L4RUCNReIt,
  check_outdated_build_client_Xass8bpYhi,
  chunk_reload_client_lRoLo0ZUQC,
  plugin_vue3_QPx4pk0FOl,
  components_plugin_KR1HBZs4kY,
  prefetch_client_E2HMxhKusz,
  nuxt_plugin_jF368xjFow,
  switch_locale_path_ssr_JfaaupOAPM,
  i18n_1BD1DB1WGj,
  plugin_GLUs1mtuUK,
  motion_r3MovnDDtb,
  plugin_wy0B721ODc,
  clickOutsideDirective_JuSoe6vGeC,
  fetchGlobalData_EOfhKKMol6,
  onLanguageSwitch_Zq3lupusSn,
  sentry_client_shVUlIjFLk
]