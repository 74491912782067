

export const useWindowStore = defineStore('window', () => {
  const windowWidth = ref(1024)
  const windowScrollY = ref(0)

  return {
    windowWidth,
    windowScrollY,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useWindowStore, import.meta.hot))
}
